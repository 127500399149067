<template>
    <v-container>
        <div v-if="!mode" class="edit-button-wrapper">
            <v-btn class="mx-2" dark medium color="#004cff" @click="onEdit">
                <v-icon dark>mdi-account-edit</v-icon>
            </v-btn>
        </div>
        <v-form>
            <div class="body-editor">
                <span class="body-label">About Us</span>
                <editor
                    ref="editor"
                    v-model="aboutUs.description"
                    :api-key="tinymceApiKey"
                    :init="editorInit"
                />
            </div>
        </v-form>
        <div v-if="mode && mode === 'edit'" class="submit-button-wrapper">
            <v-btn class="mx-2 white--text" large color="#004cff" @click="onSubmit">
                Submit
            </v-btn>
        </div>
    </v-container>
</template>

<script>
    import aboutUsService from '@/services/about-us-service'
    import Editor from '@tinymce/tinymce-vue'

    export default {
        name: 'AboutUsRoute',
        components: { Editor },
        data: () => {
            return {
                mode: null,
                apiUrl: process.env.VUE_APP_API_HOST_DOMAIN,
                tinymceApiKey: process.env.VUE_APP_TINYMCE_API_KEY,
                aboutUs: {
                    description: null
                },
                existedAboutUs: null,
                editorInit: {
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks fullscreen emoticons',
                        'insertdatetime media table paste help wordcount'
                    ],
                    fontsize_formats: '8px 10px 12px 14px 18px 24px 36px',
                    toolbar: 'undo redo | styleselect | forecolor | bold italic | fontselect fontsizeselect | emoticons | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | link image | media'
                }
            }
        },
        created() {
            aboutUsService.getAboutUs().then(resp => {
                if (resp.data.data[0]) {
                    this.aboutUs = resp.data.data[0]
                    this.existedAboutUs = this.aboutUs
                }
            })
            this.mode = this.$route.params.mode
            if (this.mode !== 'edit') {
                this.editorInit.setup = function(editor) {
                    editor.setMode('readonly')
                }
            }
        },
        methods: {
            onEdit() {
                this.$router.push(`/about-us/edit`)
            },
            async editAboutUs() {
                delete this.aboutUs.id
                await aboutUsService.editAboutUs(this.aboutUs)
                this.$router.push(`/about-us`)
            },
            async createAboutUs() {
                await aboutUsService.createAboutUs(this.aboutUs)
                this.$router.push(`/about-us`)
            },
            async onSubmit() {
                if (this.existedAboutUs) {
                    this.editAboutUs()
                } else {
                    this.createAboutUs()
                }
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 500px;
}
.tox .tox-tinymce {
    height: 50vh;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

</style>
