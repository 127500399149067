import { render, staticRenderFns } from "./AboutUsRoute.vue?vue&type=template&id=61bbd309&scoped=true&"
import script from "./AboutUsRoute.vue?vue&type=script&lang=js&"
export * from "./AboutUsRoute.vue?vue&type=script&lang=js&"
import style0 from "./AboutUsRoute.vue?vue&type=style&index=0&lang=css&"
import style1 from "./AboutUsRoute.vue?vue&type=style&index=1&id=61bbd309&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61bbd309",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VContainer,VForm,VIcon})
