import paronApi from '@/utils/paron-api'

export default {
    getAboutUs() {
        return paronApi.get('about')
    },

    createAboutUs(data) {
        return paronApi.post('about/new', data)
    },

    editAboutUs(data) {
        return paronApi.post(`about/edit`, data)
    }

}
